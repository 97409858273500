
import { defineComponent } from 'vue';
import BizIcon from '@/components/base/BizIcon.vue';
import { debounce } from '@/utils';

interface SearchResultItem {
    image?: string;
    imageAlt?: string;
    kicker?: string;
    title: string;
    text: string;
    link: string;
}

export interface SearchResult {
    results: SearchResultItem[];
    total: number;
    offset: number;
    limit: number;
    products: number;
    news: number;
    services: number;
}

const SEARCH_ENDPOINT = '/.rest/api/v1/search';
const LIMIT = 10;
const punycode = require('punycode/');

export default defineComponent({
    components: { BizIcon },
    props: {
        placeholder: String,
        resultsLabel: String,
        productsLabel: String,
        pressLabel: String,
        serviceLabel: String,
        noResultsMessage: String
    },
    data() {
        return {
            searchValue: '',
            result: null,
            results: [],
            endReached: false,
            offset: 0,
            filter: '',
            loading: false,
            error: false,
            onInput: debounce(() => this.onSearch(false))
        };
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const query = urlParams.get('query');
        if (query) {
            this.searchValue = punycode.toUnicode(query);
            this.onSearch(false);
        }

        document.addEventListener('scroll', this.onScroll);
    },
    beforeUnmount() {
        document.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            if (this.$el.getBoundingClientRect().bottom - window.innerHeight < 0) {
                if (!this.endReached) {
                    this.endReached = true;
                }
            } else {
                if (this.endReached) {
                    this.endReached = false;
                }
            }
        },
        async onSearch(append = false) {
            if (!append) {
                this.offset = 0;
            }

            this.loading = true;
            const sanitizedInput = punycode.toASCII(this.searchValue.trim());

            if (
                this.result &&
                this.result.query === sanitizedInput &&
                this.result.filter === this.filter
            ) {
                this.loading = false;
                return;
            }

            if (sanitizedInput.length >= 2) {
                if (history.pushState) {
                    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?query=${sanitizedInput}`;
                    window.history.pushState({ path: newUrl }, '', newUrl);
                }
                try {
                    this.error = false;
                    let url = `${this.$contextPath}${SEARCH_ENDPOINT}/${this.$siteName}/${this.$lang}?query=${sanitizedInput}&limit=${LIMIT}&offset=${this.offset}`;
                    if (this.filter.length) {
                        url += `&category=${this.filter}`;
                    }
                    const response = await fetch(url);
                    if (response.status === 200) {
                        this.result = (await response.json()) as SearchResult;
                        if (append) {
                            this.results.push(...this.result.results);
                        } else {
                            this.results = this.result.results;
                        }
                    } else {
                        this.result = null;
                        this.results = [];
                    }
                } catch (e) {
                    this.error = true;
                }
            } else {
                this.result = null;
                this.results = [];
                this.error = false;
            }
            this.loading = false;
        },
        toggleFilter(name: string) {
            this.filter = this.filter === name ? '' : name;
            this.onSearch();
        },
        removeHtmlTags(text: string) {
            return text
                .replace(/&amp;shy;/g, '')
                .replace(/&lt;br\s?\/?&gt;/g, ' ')
                .replace(/&shy;/g, '')
                .replace(/<br\s?\/?>/g, ' ');
        }
    },
    watch: {
        endReached() {
            if (this.endReached) {
                if (this.result && this.result.total > this.results.length) {
                    this.offset += LIMIT;
                    this.onSearch(true);
                }
            }
        }
    }
});
