
import { defineComponent } from 'vue';
import BizIcon from '@/components/base/BizIcon.vue';
import OverviewCategory from '@/components/overview/OverviewCategory.vue';
import { PRODUCTS_REST_ENDPOINT } from '@/bizerba';
import { addToState, removeFromState, cleanState } from '@/utils';
import LoadingSpinner from '@/components/base/LoadingSpinner.vue';

interface Product {
    image?: string;
    kicker?: string;
    title: string;
    text: string;
    link: string;
}

interface ProductResult {
    results: Product[];
    total: number;
    offset: number;
    limit: number;
    filters: {
        [key: string]: number;
    };
}

const URL = '/.rest/api/v1/products';
const LIMIT = 12;
const SINGLE_HERO = false;

export default defineComponent({
    components: { LoadingSpinner, BizIcon },
    props: {
        uuid: String,
        productLabel: String,
        productsLabel: String,
        showFilterLabel: String,
        resultLabel: String,
        resultsLabel: String,
        showResultsLabel: String,
        removeAllLabel: String
    },
    data() {
        return {
            ready: false,
            heroFilter: null,
            filters: [],
            availableFilters: [],
            selectedFilters: [],
            selectedFiltersBuffer: [],
            result: null,
            products: [],
            endReached: false,
            offset: 0,
            filter: '',
            loading: false,
            error: false,
            mobileFiltersOpen: false,
            mobileFiltersInnerOpen: false,
            controller: new AbortController()
        };
    },
    provide() {
        return {
            registerOverviewFilter: this.registerFilter
        };
    },
    async mounted() {
        await this.loadHash();
        if (this.heroFilter && this.heroFilter.categories) {
            this.heroFilter.categories = this.heroFilter.categories
                .filter(
                    x =>
                        Object.prototype.hasOwnProperty.call(this.result.filters, x.categoryId) &&
                        this.result.filters[x.categoryId] > 0
                )
                .slice(0, 6);
        }
        document.addEventListener('scroll', this.onScroll);
        window.addEventListener('hashchange', this.updateHash);
    },
    beforeUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('hashchange', this.updateHash);
    },
    methods: {
        registerFilter(filter) {
            if (filter.hero) {
                this.heroFilter = filter;
            } else {
                this.filters.push(filter);
            }
            this.selectedFilters.push([]);
            this.selectedFiltersBuffer.push([]);
        },
        async getProducts(append = false, disable = true, cancel = true) {
            if (!append) {
                this.offset = 0;
            }
            if (this.loading && cancel) {
                this.controller.abort();
                this.loading = false;
                this.controller = new AbortController();
            }
            try {
                this.loading = true;
                this.error = false;
                let url = `${this.$contextPath}${PRODUCTS_REST_ENDPOINT}/${this.$siteName}/${this.$lang}/${this.uuid}?limit=${LIMIT}&offset=${this.offset}`;
                if (this.filterString.length) {
                    url += `&filter=${this.filterString}`;
                }
                const response = await fetch(url, { signal: this.controller.signal });
                if (response.status === 200) {
                    this.result = (await response.json()) as ProductResult;
                    if (append) {
                        this.products.push(...this.result.results);
                    } else {
                        this.products = this.result.results;
                    }
                    this.availableFilters = this.filters
                        .filter(x => this.hasCategories(x, disable))
                        .map(f => {
                            f.categories = f.categories.filter(x =>
                                this.hasCategory(x.categoryId, disable)
                            );
                            return f;
                        });
                } else {
                    this.result = null;
                    this.results = [];
                }
            } catch (e) {
                this.error = true;
            }
            this.loading = false;
        },
        selectMobileFilter(e: Event) {
            const target = e.target as HTMLInputElement;
            this.toggleFilter(target.value, true);
        },
        selectFilter(e: Event) {
            const target = e.target as HTMLInputElement;
            this.toggleFilter(target.value);
        },
        selectHeroFilter(filter: InstanceType<typeof OverviewCategory>) {
            if (SINGLE_HERO) {
                // find a potentially selected hero (not the current one) filter and deselected it first
                const index = this.selectedFilters.findIndex(f =>
                    this.heroFilter.categories
                        .map(x => x.categoryKey)
                        .filter(x => x !== filter.categoryKey)
                        .some(x => x === f)
                );
                if (index >= 0) {
                    this.selectedFilters.splice(index, 1);
                }
            }
            this.toggleFilter(filter.categoryKey);
        },
        toggleFilter(filterKey: string, buffer = false, updateHash = true) {
            const filters = buffer ? this.selectedFiltersBuffer : this.selectedFilters;
            const filter = this.filters
                .concat(this.heroFilter)
                .find(f => f.categories.some(c => c.categoryKey === filterKey));
            if (!filter) {
                return;
            }
            const filterIndex = (this.heroFilter ? [this.heroFilter] : [])
                .concat(this.filters)
                .findIndex(x => x.filterKey === filter.filterKey);
            const index = filters[filterIndex].indexOf(filterKey);
            if (index === -1) {
                if (updateHash) {
                    addToState(filter.filterKey, filterKey);
                }
                filters[filterIndex].push(filterKey);
            } else {
                if (updateHash) {
                    removeFromState(filter.filterKey, filterKey);
                }
                filters[filterIndex].splice(index, 1);
            }
            this.getProducts(false);
        },
        isSelected(key) {
            return this.selectedFilters.some(f => f.some(x => x === key));
        },
        toggleMobileFilters(save) {
            if (this.mobileFiltersOpen) {
                this.mobileFiltersInnerOpen = false;
                setTimeout(() => {
                    this.mobileFiltersOpen = false;
                    if (save) {
                        // replace selected filters with buffer
                        this.selectedFilters = [...this.selectedFiltersBuffer];
                    } else {
                        // get products with old filters
                        this.getProducts();
                    }
                    this.selectedFiltersBuffer = this.selectedFiltersBuffer.map(() => []);
                }, 300);
                document.body.classList.toggle('overflow-hidden', false);
            } else {
                this.mobileFiltersOpen = true;
                this.$nextTick(() => {
                    this.mobileFiltersInnerOpen = true;
                });
                document.body.classList.toggle('overflow-hidden', true);
                this.selectedFiltersBuffer = [...this.selectedFilters];
            }
        },
        hasCategory(id, disable = true): boolean {
            if (!this.result) {
                return false;
            }
            return (
                Object.keys(this.result.filters).includes(id) &&
                (this.result.filters[id] > 0 || disable)
            );
        },
        hasCategories(filter, disable = true): boolean {
            return filter.categories.some(x => this.hasCategory(x.categoryId, disable));
        },
        hasEnabledCategories(filter, buffer = false): boolean {
            if (!filter.categories) {
                return false;
            }
            return !filter.categories.every(x => this.isCategoryDisabled(filter, x, buffer));
        },
        isCategoryDisabled(filter, category, buffer = false): boolean {
            const selected = buffer ? this.selectedFiltersBuffer : this.selectedFilters;
            if (
                selected.includes(category.categoryKey) ||
                selected.some(key => filter.categories.map(x => x.categoryKey).includes(key))
            ) {
                return false;
            }
            if (this.result) {
                return Object.prototype.hasOwnProperty.call(
                    this.result.filters,
                    category.categoryId
                )
                    ? this.result.filters[category.categoryId] === 0
                    : false;
            }
            return false;
        },
        removeCategory(category, buffer = false) {
            const filters = buffer ? this.selectedFiltersBuffer : this.selectedFilters;
            const filter = filters.find(x => x.some(c => c === category.categoryKey));
            if (filter) {
                const index = filter.indexOf(category.categoryKey);
                if (index >= 0) {
                    filter.splice(index, 1);
                    this.getProducts();
                }
            }
            const parentFilter = this.filters.find(x =>
                x.categories.some(c => c.categoryKey === category.categoryKey)
            );
            if (parentFilter) {
                removeFromState(parentFilter.filterKey, category.categoryKey);
            }
        },
        clearFilters(buffer: boolean) {
            if (buffer) {
                this.selectedFiltersBuffer = this.selectedFiltersBuffer.map(() => []);
            } else {
                this.selectedFilters = this.selectedFilters.map(() => []);
            }
            cleanState();
            this.getProducts();
        },
        selectedSubcategories(filter, buffer = false) {
            return filter.categories.filter(s =>
                buffer
                    ? this.selectedFiltersBuffer.some(x => x.includes(s.categoryKey))
                    : this.selectedFilters.some(x => x.includes(s.categoryKey))
            );
        },
        onScroll() {
            if (this.$refs.el.getBoundingClientRect().bottom - window.innerHeight < 0) {
                if (!this.endReached) {
                    this.endReached = true;
                }
            } else {
                if (this.endReached) {
                    this.endReached = false;
                }
            }
        },
        async loadHash(update = false) {
            const hash = location.hash.replace(/^#/, '');
            const filters = hash
                .split('&')
                .filter(x => x.includes(':'))
                .map(x => x.split(':'))
                .filter(x => x.length === 2)
                .filter(
                    f =>
                        (this.heroFilter ? [this.heroFilter] : [])
                            .concat(this.filters)
                            .findIndex(x => x.filterKey === f[0]) >= 0
                );

            filters.forEach((f, i) => {
                const filterCategory = (this.heroFilter ? [this.heroFilter] : [])
                    .concat(this.filters)
                    .find(x => x.filterKey === f[0]);
                if (filterCategory) {
                    f[1].split(',').forEach(key => {
                        this.toggleFilter(key, false, update && i + 1 === filters.length);
                    });
                }
            });
            return this.getProducts(false, update);
        },
        async updateHash() {
            return this.loadHash(true);
        }
    },
    computed: {
        filterString(): string {
            return (this.mobileFiltersOpen ? this.selectedFiltersBuffer : this.selectedFilters)
                .map(x => x.join(','))
                .filter(x => x.length)
                .join(',');
        },
        heroActive() {
            if (!this.heroFilter || !this.heroFilter.categories) {
                return false;
            }
            return this.selectedFilters.some(f =>
                this.heroFilter.categories.map(x => x.categoryKey).includes(f)
            );
        }
    },
    watch: {
        endReached() {
            if (this.endReached) {
                if (this.result && this.result.total > this.products.length) {
                    this.offset += LIMIT;
                    this.getProducts(true, false, false);
                }
            }
        }
    }
});
