
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        items: {
            type: Array as () => { anchor: string; title: string }[]
        }
    },
    data() {
        return {
            active: '',
            contrast: false
        };
    },
    mounted() {
        // get all section to get anchor id
        const sections = document.querySelectorAll('section');
        // on scroll get current anchor id, and see if it's contrast
        window.onscroll = () => {
            const windowHeight = window.innerHeight;
            sections.forEach(section => {
                // get section values
                const sectionHeight = section.scrollHeight;
                const sectionStart = section.offsetTop;
                const sectionEnd = sectionStart + sectionHeight;

                // only change active when it's in the middle of the screen and it isn't sticky
                if (
                    scrollY >= sectionStart - windowHeight / 2 &&
                    scrollY <= sectionEnd - windowHeight / 2 &&
                    this.active !== section.getAttribute('id') &&
                    !section.classList.contains('sticky')
                ) {
                    this.active = section.getAttribute('id');
                    // if section have class text-white we will have content navigation in white
                    if (section.classList.contains('text-white')) {
                        this.contrast = true;
                    } else {
                        this.contrast = false;
                    }
                }
            });
        };
    }
});
