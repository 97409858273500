import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "clip-0 -mx-8 min-w-full overflow-hidden px-8 lg:mx-0 lg:overflow-visible" }
const _hoisted_2 = {
  key: 0,
  ref: "progressContainer",
  class: "mt-8 h-0.5 w-full bg-black/20 lg:hidden"
}
const _hoisted_3 = {
  class: "h-full bg-black",
  ref: "progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({ 'relative lg:h-screen lg:pb-20': !_ctx.editMode })
    }, [
      _createElementVNode("div", {
        ref: "container",
        class: _normalizeClass(["flex h-full lg:-mr-4", [_ctx.editMode ? 'flex-wrap' : 'flex-nowrap']])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      (_ctx.numItems > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, null, 512)
          ], 512))
        : _createCommentVNode("", true),
      (!_ctx.editMode)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "absolute bottom-0 left-0 right-0 hidden h-10 lg:-mx-8 lg:block lg:h-20",
            style: _normalizeStyle({ background: _ctx.nextColor })
          }, null, 4))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}