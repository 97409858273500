const plugin = require('tailwindcss/plugin');

module.exports = {
    content: [
        './templates/**/*.ftl',
        './includes/templates/*.ftl',
        './macros/*.ftl',
        './lib/*.ftl',
        './ts/**/*.vue',
        './ts/**/*.ts'
    ],
    corePlugins: {
        container: false
    },
    theme: {
        colors: {
            'current': 'currentColor',
            'transparent': 'transparent',
            'white': '#FFFFFF',
            'gray': {
                100: '#EEEDED',
                200: '#F8F8F8',
                300: '#FCFCFC',
                350: '#e5e5e5',
                400: '#616161',
                450: '#272727',
                470: '#1f1f1f',
                500: '#111111'
            },
            'black': '#000000',
            'cyan': '#009DE0',
            'light-blue': '#CCEBF9',
            'light-mid-blue': '#99D8F3',
            'mid-blue': '#65C4EB',
            'mid-blue-2': '#0076A7',
            'dark-mid-blue': '#004F70',
            'dark-blue': '#002737',
            'warn': '#eab308',
            'green': '#02640f',
            'red': '#a31001'
        },
        hyphens: {
            none: 'none',
            auto: 'auto',
            manual: 'manual'
        },
        extend: {
            animation: {
                'fade-r': 'fade-r .3s ease-out .15s forwards',
                'slide-up': 'slide-up .3s ease-out .3s both',
                'load': 'load 3s ease-in infinite'
            },
            backgroundPosition: {
                center45: 'center 45%'
            },
            backgroundImage: {
                'check': "url('../assets/img/check.svg')",
                'check-white': "url('../assets/img/check-white.svg')",
                'pattern-default': "url('../assets/img/Pattern-Default-Desktop.svg')",
                'pattern-semi': "url('../assets/img/Pattern-Semi-Desktop.svg')",
                'pattern-contrast': "url('../assets/img/Pattern-Contrast-Desktop.svg')",
                'pattern-default-mobile': "url('../assets/img/Pattern-Default-Mobile.svg')",
                'pattern-semi-mobile': "url('../assets/img/Pattern-Semi-Mobile.svg')",
                'pattern-contrast-mobile': "url('../assets/img/Pattern-Contrast-Mobile.svg')",
                'flare': "url('../assets/img/flare.webp')",
                'fallback': "url('../assets/img/produktgrau_1200px.png')"
            },
            opacity: {
                15: '0.15'
            },
            fontFamily: {
                sans: ['Roboto', 'sans-serif'],
                quote: ['Playfair Display', 'serif']
            },
            fontSize: {
                'sm': ['0.875rem', '1.25rem'],
                'base': ['1rem', '1.5rem'],
                'md': ['1rem', '1.75rem'],
                'highlight': ['1.125rem', '1.375rem'],
                'lg': ['1.25rem', '1.75rem'],
                'xl': ['1.5rem', '2rem'],
                'xl-quote': ['1.5rem', '2.25rem'],
                '2xl': ['1.625rem', '2rem'],
                '3xl': ['1.75rem', '2.25rem'],
                '4xl': ['1.875rem', '2.5rem'],
                '5xl': ['2.25rem', '2.75rem'],
                '6xl': ['2.5rem', '3rem'],
                '6xl-quote': ['2.5rem', '3.5rem'],
                '6xl-quote-long': ['2rem', '3rem'],
                '7xl': ['3.5rem', '4.5rem'],
                '8xl': ['4.5rem', '5.5rem'],
                '8.5xl': ['5.5rem', '7rem'],
                '9xl': ['7rem', '8rem'],
                '10xl': ['8rem', '8.5rem'],
                'initial-mobile': ['5rem', '4rem'],
                'initial': ['11.25rem', '7.25rem']
            },
            height: {
                138: '34.5rem',
                150: '37.5rem',
                160: '40rem',
                190: '47.5rem',
                212: '53.125rem'
            },
            keyframes: {
                'fade-r': {
                    '0%': { opacity: 0, transform: 'translateX(-50%)' },
                    '100%': { opacity: 1, transform: 'translateX(0)' }
                },
                'slide-up': {
                    '0%': { transform: 'translateY(100%)' },
                    '100%': { transform: 'translateY(0%)' }
                },
                'load': {
                    '0%': { opacity: 0, transform: 'translateX(-2rem)' },
                    '5%': { opacity: 1, transform: 'translateX(0)' },
                    '50%': { opacity: 1, transform: 'translateX(0)' },
                    '55%': { opacity: 0, transform: 'translateX(2rem)' }
                }
            },
            lineClamp: {
                7: '7'
            },
            maxWidth: {
                '1/2': '50%',
                '9/10': '90%',
                'screen-2xl': '1192px',
                'screen-3xl': '1444px'
            },
            minHeight: {
                44: '11rem',
                stage: '960px'
            },
            gridTemplateColumns: {
                'auto-1': 'auto 1fr'
            },
            padding: {
                '9/4': '44.44%',
                '16/9': '56.25%',
                '3/2': '66.66%',
                '1/1': '100%',
                '2/3': '150%'
            },
            spacing: {
                4.5: '1.125rem',
                18: '4.5rem',
                22: '5.5rem',
                25: '6.375rem',
                26: '6.5rem',
                94: '23.5rem',
                102: '25.5rem',
                145: '36.25rem'
            },
            strokeWidth: {
                3: '3px'
            },
            transitionDuration: {
                5000: '5000ms'
            },
            typography: theme => ({
                'DEFAULT': {
                    css: {
                        '--tw-prose-body': theme('colors.gray[400]'),
                        '--tw-prose-bullets': 'inherit',
                        '--tw-prose-bold': 'inherit',
                        '--tw-prose-links': 'inherit',
                        'lineHeight': theme('fontSize.base')[1],
                        'maxWidth': 'none',
                        'h5': {
                            color: theme('colors.gray[500]'),
                            marginBottom: '0.5rem'
                        },
                        'p': {
                            marginTop: '0.5rem'
                        },
                        'a:hover': {
                            color: theme('colors.black')
                        }
                    }
                },
                'contrast': {
                    css: {
                        'a:hover': {
                            color: theme('colors.gray[100]')
                        },
                        'h5': {
                            color: theme('colors.white')
                        }
                    }
                },
                'md': {
                    css: {
                        fontSize: '1rem',
                        lineHeight: '28px'
                    }
                },
                'columns': {
                    css: {
                        maxWidth: 'none',
                        columnCount: 2,
                        columnGap: '2rem'
                    }
                },
                'quotes': {
                    css: {
                        'em': {
                            fontFamily: theme('fontFamily.quote[0]'),
                            fontSize: '1.875rem',
                            lineHeight: '2.5rem',
                            fontStyle: 'normal',
                            color: theme('colors.gray[500]')
                        },
                        'b, bold': {
                            fontWeight: 'bold'
                        }
                    }
                },
                'list': {
                    css: {
                        'ul': {
                            margin: 0,
                            padding: 0,
                            listStylePosition: 'inside'
                        },
                        'li': {
                            margin: 0,
                            padding: '1.75rem 0',
                            borderTopWidth: 0,
                            borderBottomWidth: 1,
                            listStyleType: 'none'
                        },
                        'li:last-child': {
                            borderBottomWidth: 0,
                            borderColor: theme('colors.transparent')
                        },
                        'li > ul > li': {
                            margin: 0,
                            paddingTop: '0.625rem',
                            paddingBottom: 0,
                            borderBottomWidth: 0,
                            color: theme('colors.gray[400]')
                        }
                    }
                },
                'ordered-list': {
                    css: {
                        'ol': {
                            margin: 0,
                            padding: 0,
                            listStylePosition: 'inside'
                        },
                        'li > ol > li': {
                            margin: 0,
                            paddingTop: '0.625rem',
                            paddingBottom: 0,
                            borderBottomWidth: 0,
                            color: theme('colors.gray[400]')
                        }
                    }
                },
                'list-first': {
                    css: {
                        'ul:first-child': {
                            marginTop: '-1.75rem'
                        }
                    }
                },
                'list-contrast': {
                    css: {
                        'li': {
                            color: 'inherit'
                        },
                        'li > ul > li': {
                            color: 'rgba(252, 252, 252, 0.7)'
                        }
                    }
                },
                'highlights-list': {
                    css: {
                        'li': {
                            fontWeight: 700
                        },
                        'li > ul > li': {
                            fontWeight: 400
                        }
                    }
                },
                'table': {
                    css: {
                        '--tw-prose-headings': 'inherit',
                        '--tw-prose-body': 'inherit',
                        'table': {
                            width: 'unset !important',
                            minWidth: '100%'
                        },
                        'tr:first-child': {
                            borderBottomWidth: '2px !important',
                            borderBottomColor: 'hsla(0,0%,7%,.3) !important',
                            fontWeight: 'bold !important'
                        },
                        'tr': {
                            borderBottomWidth: '1px',
                            borderBottomColor: 'hsla(0,0%,7%,.3)'
                        },
                        'tr:last-child': {
                            borderBottomWidth: 0
                        },
                        'td': {
                            fontSize: '16px',
                            padding: '28px 16px 28px 0',
                            color: 'currentColor'
                        },
                        'td > ul > li': {
                            border: 'none',
                            padding: 0,
                            listStyleType: 'inherit'
                        },
                        'td > ul li > ul > li': {
                            border: 'none',
                            padding: 0,
                            listStyleType: 'inherit'
                        }
                    }
                },
                'table-contrast': {
                    css: {
                        'tr:first-child': {
                            borderBottomWidth: '2px !important',
                            borderBottomColor: 'hsla(0,3%,93%,.3) !important',
                            fontWeight: 'bold !important'
                        },
                        'tr': {
                            borderBottomWidth: '1px',
                            borderBottomColor: 'hsla(0,3%,93%,.3)'
                        },
                        'tr:last-child': {
                            borderBottomWidth: 0
                        }
                    }
                },
                'hero': {
                    css: {
                        'li': {
                            display: 'block !important'
                        },
                        'li > ul > li': {
                            fontWeight: 'normal !important'
                        },
                        'li > ul > li::before': {
                            content: 'none !important'
                        }
                    }
                },
                'list-headline-top': {
                    css: {
                        'ul': {
                            borderBottomWidth: 1
                        },
                        'ul:last-child': {
                            borderBottomWidth: 0,
                            borderColor: theme('colors.transparent')
                        },
                        'h5': {
                            paddingTop: '0.75rem'
                        }
                    }
                },
                'career': {
                    css: {
                        'h2': {
                            marginBottom: '1.5rem',
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem'
                        },
                        'ul': {
                            color: theme('colors.gray[400]'),
                            listStylePosition: 'outside',
                            paddingLeft: '1rem',
                            listStyleType: 'disc',
                            fontSize: '1rem',
                            lineHeight: '1.5rem'
                        },
                        'ul > li': {
                            paddingBottom: '0.5rem'
                        }
                    }
                },
                'career-lg': {
                    css: {
                        h2: {
                            fontSize: '1.5rem',
                            lineHeight: '2rem'
                        }
                    }
                },
                'gdpr': {
                    css: {
                        a: {
                            textDecoration: 'underline'
                        }
                    }
                }
            }),
            zIndex: {
                '-1': '-1'
            }
        }
    },
    variants: {
        extend: {
            borderOpacity: ['last'],
            borderWidth: ['first']
        }
    },
    plugins: [
        require('@tailwindcss/line-clamp'),
        require('@tailwindcss/typography'),
        require('tailwindcss-labeled-groups')(['1']),
        plugin(({ addVariant, matchUtilities, theme, e }) => {
            addVariant('active-slide', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `.slide-active .${e(`active-slide${separator}${className}`)}`;
                });
            });
            addVariant('visible-slide', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `.slide-active .${e(
                        `visible-slide${separator}${className}`
                    )}, .slide-visible .${e(`visible-slide${separator}${className}`)}`;
                });
            });
            addVariant('child-affect', '.child-affect &');
            matchUtilities(
                {
                    hyphens: value => ({
                        hyphens: value
                    })
                },
                { values: theme('hyphens') }
            );
        })
    ]
};
