
/* eslint-disable prettier/prettier */
import { defineComponent } from 'vue';
import BizIcon from '@/components/base/BizIcon.vue';
import Careers, { Career } from '../../library/Careers';

export default defineComponent({
    components: { BizIcon },
    props: {
        buttonLabel: String,
        seeOverview: String,
        country: String,
        company: String,
        jobCategory: String,
        title: String
    },
    data() {
        return {
            careers: [] as Career[],
            totalResults: 0
        };
    },
    mounted() {
        this.loadCareers();

        // animation
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.$refs.othercareers.classList.add('translate-x-0');
                    this.$refs.othercareers.classList.remove('-translate-x-3/4');
                } else {
                    this.$refs.othercareers.classList.add('-translate-x-3/4');
                    this.$refs.othercareers.classList.remove('translate-x-0');
                }
            });
        }, {
            threshold: 0.1,
            rootMargin: '-40px'
        });
        observer.observe(this.$refs.othercareers);
    },
    methods: {
        async loadCareers() {
            this.loading = true;
            try {
                // get 3 careers
                this.careers = await Careers.loadCareers('title-asc', 3, {
                    country: this.country,
                    company: this.company,
                    jobCategory : this.jobCategory
                });
            } catch (e) {
                console.error(e);
            }
            this.loading = false;
        }
    },
    computed: {
        parentHash() {
            return `#country:${encodeURIComponent(this.country)}&company:${encodeURIComponent(this.company)}`;
        },
        parentUrl() {
            const currentUrl = window.location.pathname;
            const segments = currentUrl.split('/').filter(Boolean);
            segments.pop();
            return '/' + segments.join('/');
        }
    }
});
