
/* eslint-disable dot-notation */
import { defineComponent } from 'vue';
import BizIcon from '@/components/base/BizIcon.vue';
import BizDropdownGroup from '@/components/base/BizDropdownGroup.vue';
import LoadingSpinner from '@/components/base/LoadingSpinner.vue';
import Careers, { Career } from '../../library/Careers';
import { v4 as uuid } from 'uuid';

export default defineComponent({
    components: { BizIcon, BizDropdownGroup, LoadingSpinner },
    props: {
        country: String,
        preFilters: { type: Array as () => Array<{ key: string; value: string }> },
        filtersAvailable: Array,
        noJobsTitle: String,
        noJobsText: String,
        resetFiltersText: String
    },
    data() {
        return {
            uid: uuid(),
            careers: [] as Career[],
            // filters
            filters: [],
            searchValueActive: { key: '', label: '' },
            searchValue: '',
            international: true,
            allCountries: [],
            // limits control
            currentPage: 0,
            limit: 8,
            totalResults: 0,
            loading: false,
            firstLoad: true
        };
    },
    created() {
        this.searchValueActive = this.filtersAvailable.find(element => element.key === 'search');
        // remove search and prefilters to filters Object
        this.filters = this.filtersAvailable.filter(
            element =>
                element.key !== 'search' &&
                !this.preFilters.some(prefilter => prefilter.key === element.key)
        );
        window.addEventListener('hashchange', this.updateHash);
        this.loadCareersFilters();
    },
    mounted() {
        this.createIntersectionObserver();
    },
    beforeUnmount() {
        window.removeEventListener('hashchange', this.updateHash);
    },
    methods: {
        /*
         * LOAD CAREERS AND STRUCTURE FUNCTIONS
         */
        async loadCareersFilters() {
            this.loading = true;
            try {
                const LoadData = await Careers.loadMoreCareers(
                    this.firstLoad,
                    'title-asc',
                    this.limit,
                    this.offset,
                    this.searchValue,
                    this.firstLoad ? this.searchCriteriaFirstLoad : this.searchCriteria,
                    this.international ? [] : this.allCountries
                );
                if (this.offset) {
                    this.careers = [...this.careers, ...LoadData.data];
                } else if (LoadData && LoadData.data) {
                    this.careers = LoadData.data;
                    // filters and hash
                    if (this.firstLoad) {
                        this.filtersStrucutre(LoadData);
                        this.readHash();
                    } else {
                        this.filtersUpdate(LoadData);
                    }
                    // total Results
                    this.totalResults = LoadData.totalResults ? LoadData.totalResults : 0;
                }
            } catch (e) {
                console.error(e);
            }
            if (this.currentPage === 0 && !this.firstLoad) {
                this.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            this.loading = false;
            this.firstLoad = false;
        },

        filtersStrucutre(LoadData) {
            Object.keys(LoadData.filters).forEach(filterKey => {
                const filter = this.filters.find(x => x.key === filterKey);
                if (filter) {
                    const values = LoadData.filters[filterKey];
                    if (filter.key !== 'country' && this.$refs[filter.label][0]) {
                        filter.dropdown = this.$refs[filter.label][0];
                    } else {
                        this.allCountries = values;
                    }
                    filter.allValues = values.map(value => {
                        return {
                            name: value,
                            haveResults: true
                        };
                    });
                }
            });
        },

        filtersUpdate(LoadData) {
            if (!(LoadData && LoadData.filters)) {
                return;
            }
            this.filters.forEach(filter => {
                filter.allValues.forEach(filterValue => {
                    const isInLoadData = LoadData.filters[filter.key].includes(filterValue.name);
                    filterValue.haveResults = isInLoadData || filter.selected === filterValue.name;
                });
            });
        },

        /*
         * PAGES / LOAD MORE ITEMS
         */
        async loadMoreItems() {
            if (this.totalResults > this.limit * (this.currentPage + 1)) {
                this.currentPage++;
                this.loadCareersFilters();
            }
        },

        createIntersectionObserver() {
            const options = {
                root: null,
                threshold: 0.1
            };

            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.loadMoreItems();
                    }
                });
            }, options);

            observer.observe(this.$refs.observer);
        },

        /*
         * FILTERS TOOGLES
         */
        toogleFilter(item, filter) {
            filter.selected = filter.selected !== item.value ? item.value : '';
            this.currentPage = 0;
            this.updateHash();
        },

        toogleCountry(country, filter) {
            filter.selected = filter.selected !== country.name ? country.name : '';
            this.international = false;
            this.currentPage = 0;
            this.updateHash();
        },

        toggleInternational() {
            if (this.international) {
                this.international = false;
            } else {
                const countryFilter = this.filters.find(filter => filter.key === 'country');
                if (countryFilter && countryFilter.selected) {
                    countryFilter.selected = '';
                }
                this.international = true;
            }
            this.currentPage = 0;
            this.updateHash();
            this.loadCareersFilters();
        },

        resetFilters() {
            this.filters.forEach(filter => {
                if (filter.key !== 'country') {
                    filter.dropdown.setValue();
                    filter.selected = '';
                } else {
                    filter.selected = '';
                }
            });
            this.international = false;
            this.searchValue = '';
            this.updateHash();
        },

        /*
         * HASH
         */
        readHash() {
            const hash = location.hash.replace(/^#/, '');

            // get filter pairs
            const pairs = hash.split('&');
            const hashFilters = {};
            pairs.forEach(pair => {
                const [key, value] = pair.split(':');
                if (value) {
                    hashFilters[key] = decodeURIComponent(value);
                }
            });

            // update filters
            this.filters.forEach(filter => {
                if (hashFilters[filter.key] && filter.dropdown) {
                    this.$nextTick(() => {
                        filter.dropdown.setValue(hashFilters[filter.key]);
                        filter.selected = hashFilters[filter.key];
                    });
                }
            });

            if (hashFilters['search']) {
                this.searchValue = hashFilters['search'];
            }
        },
        updateHash() {
            const hashParts = [];
            this.filters.forEach(filter => {
                if (filter.selected) {
                    hashParts.push(`${filter.key}:${filter.selected}`);
                }
            });

            if (this.searchValue) {
                hashParts.push(`search:${encodeURIComponent(this.searchValue)}`);
            }

            const newHash = '#' + hashParts.join('&');
            window.location.hash = newHash;
        }
    },

    computed: {
        offset() {
            return this.currentPage * this.limit;
        },
        itemBeginningLink() {
            return window.location.pathname + '/';
        },
        searchCriteriaFirstLoad() {
            const searchCriteriaFirstLoad = {};
            // filter + pre filters
            this.filtersAvailable.forEach(element => {
                if (element.key !== 'search') {
                    searchCriteriaFirstLoad[element.key] = '';
                }
            });
            this.preFilters.forEach(element => {
                searchCriteriaFirstLoad[element.key] = element.value;
            });
            return searchCriteriaFirstLoad;
        },
        searchCriteria() {
            const searchCriteriaWithValues = {};
            // filters values + pre filters
            this.filters.forEach(element => {
                searchCriteriaWithValues[element.key] = element.selected ? element.selected : '';
            });
            this.preFilters.forEach(element => {
                searchCriteriaWithValues[element.key] = element.value;
            });
            return searchCriteriaWithValues;
        },
        hasSearchPoints() {
            return (
                this.searchValue ||
                this.international ||
                this.filters.some(elemento => elemento.selected !== '')
            );
        }
    },

    watch: {
        searchValue() {
            this.currentPage = 0;
            this.updateHash();
            this.loadCareersFilters();
        },
        searchCriteria() {
            if (!this.firstLoad) {
                this.loadCareersFilters();
            }
        },
        firstLoad() {
            const countryFilter = this.filters.find(filter => filter.key === 'country');
            if (
                this.country &&
                countryFilter &&
                !countryFilter.selected &&
                countryFilter.allValues.find(value => value.name === this.country)
            ) {
                countryFilter.selected = this.country;
                this.international = false;
                this.currentPage = 0;
                this.updateHash();
            }
        }
    }
});
