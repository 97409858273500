// to be used at Career Others and Career List
/* eslint-disable prettier/prettier */
const CAREERS_API = '/.rest/api/v2/careers?locale=';

export interface Career {
    id: number;
    jobId: string;
    feed: string;
    title: string;
    language: string;
    locationTitle: string;
    department: string;
    jobType: string;
    city: string;
    region: string;
    country: string;
    channel: string;
    highlights: string[];
    description: string;
    url: string;
    applicationLink: string;
    startDate: number;
    endDate: number;
    lastUpdated: number;
    version: number;
    // custom properties
    company: string;
    jobCategory: string;
    postingDetailUrl: string;
}
interface CareersFiltersResponse {
    data: Career[];
    filters: Record<string, Array<string>>;
    totalResults: number;
}

export default class Careers {
    static abortController = new AbortController();

    private static apiBaseUrl(): string {
        // eslint-disable-next-line dot-notation
        return window['contextPath'] + CAREERS_API + window['lang'];
    }
    
    // get Careers - user for careerOthers
    public static async loadCareers(sorting: string, limit: number, searchCriteria: Record<string, unknown>): Promise<Array<Career>> {

        const url = this.apiBaseUrl() +
                    '&sortBy=' + sorting +
                    '&limit=' + limit +
                    '&searchCriteria=' + encodeURIComponent(JSON.stringify(searchCriteria));
        try {
            const response = await fetch(url);
            if (response.status === 200) {
                const data = await response.json();
                return data.data;
            }
            return [];
        } catch (e) {
            console.error(e);
            return [];
        }
    }
    

    // get Careers + Filters - user for careerList
    public static async loadMoreCareers(first: boolean, sorting: string, limit: number, offset: number, search: string, searchCriteria: string, countries: Array<string>): Promise<CareersFiltersResponse> {
        this.abortController.abort();
        this.abortController = new AbortController();

        const url = this.apiBaseUrl() +
                    countries.map(c => '&country=' + c).join('') +
                    '&sortBy=' + sorting +
                    '&limit=' + limit +
                    '&offset=' + offset +
                    '&keyword=' + encodeURI(search.trim()) +
                    '&searchCriteria=' + encodeURIComponent(JSON.stringify(searchCriteria));
        try{
            const response = first ? await fetch(url) : await fetch(url, { signal: this.abortController.signal });
            if (response.status === 200) {
                return await response.json();
            }
            return { data: {}, filters: {}, totalResults: 0 } as CareersFiltersResponse;
        } catch (e) {
            if (e.name !== 'AbortError') {
                console.error(e);
                return { data: {}, filters: {}, totalResults: 0 } as CareersFiltersResponse;
            }
        }
    }
}