
import { defineComponent } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';
import MatchMedia = gsap.MatchMedia;

export default defineComponent({
    props: {
        editMode: Boolean,
        center: {
            type: Object,
            default: () => ({ x: 50, y: 50 })
        }
    },
    data() {
        return {
            nextColor: '',
            twConfig: resolveConfig(tailwindConfig),
            numItems: 0
        };
    },
    created() {
        if (!this.editMode) {
            gsap.registerPlugin(ScrollTrigger);
        }
    },
    mounted() {
        if (!this.editMode) {
            const mm: MatchMedia = gsap.matchMedia();
            const breakPoint = parseInt(this.twConfig.theme.screens.lg);
            if (this.$refs.container) {
                this.numItems = this.$refs.container.children.length - 1;
            }

            mm.add(
                {
                    isDesktop: `(min-width: ${breakPoint}px)`,
                    isMobile: `(max-width: ${breakPoint - 1}px)`
                },
                context => {
                    const { isDesktop } = context.conditions;

                    const items: HTMLElement[] = Array.from(this.$refs.container.children);
                    if (items.length < 2) {
                        return;
                    }

                    gsap.set(items[1], {
                        scale: isDesktop ? 2 : window.innerWidth / this.$refs.container.clientWidth,
                        transformOrigin: `${this.center.x}% ${this.center.y}%`
                    });
                    items.forEach((item, key) => {
                        if (key > 1) {
                            gsap.set(item, {
                                opacity: 0
                            });
                        }
                    });
                    const width = isDesktop
                        ? (window.innerWidth / 3) * items.length
                        : (this.$refs.container.clientWidth + 8) * (items.length - 1) - 8;

                    const containerWidth = isDesktop
                        ? window.innerWidth
                        : this.$refs.container.clientWidth;

                    gsap.set(this.$refs.container, { width });
                    this.$nextTick(() => {
                        if (!isDesktop && this.$refs.progressContainer) {
                            gsap.set(this.$refs.progressContainer, {
                                opacity: 0
                            });
                            gsap.set(this.$refs.progress, {
                                width: 0
                            });
                        }

                        const tl = gsap.timeline({
                            scrollTrigger: {
                                start: 'center 50%',
                                trigger: this.$el,
                                pin: true,
                                pinSpacing: 'margin',
                                scrub: true,
                                end: '+=' + width * 0.6
                            }
                        });
                        tl.to(items[1], {
                            scale: 1,
                            duration: 0.3
                        });
                        tl.to(items.slice(2), {
                            opacity: 1,
                            duration: 0.1
                        });
                        if (!isDesktop) {
                            tl.to(this.$refs.progressContainer, {
                                opacity: 1
                            });
                        }
                        if (this.numItems > 1) {
                            tl.to(this.$refs.container, {
                                x:
                                    '-=' +
                                    (this.numItems > 2
                                        ? Math.abs(containerWidth - width)
                                        : isDesktop
                                        ? containerWidth * 0.2
                                        : width / 2),
                                ease: 'none'
                            });
                        }
                        if (!isDesktop) {
                            tl.to(
                                this.$refs.progress,
                                {
                                    width: containerWidth
                                },
                                '<'
                            );
                        }
                    });
                }
            );
        }
        this.getNextColor();
    },
    methods: {
        getNextColor() {
            const currentSection = document.querySelector('.tr-product-gallery-section');
            const nextSection = currentSection.nextElementSibling;

            const computedStyle = window.getComputedStyle(nextSection);
            const backgroundColor = computedStyle.backgroundColor;

            // if transparent define as white, if not the next section color background
            if (backgroundColor === 'rgba(0, 0, 0, 0)' || backgroundColor === 'transparent') {
                this.nextColor = '#FFFFFF';
            } else {
                this.nextColor = backgroundColor;
            }
        }
    }
});
